import React, {useState} from 'react'
import { Editor } from '@tinymce/tinymce-react';

const Tinymce = props => {

  const [content, setContent] = useState(props.content)

  return <div style={{width: '79%', marginLeft: '20%'}}>
    <input type="hidden" name={props.name} value={content} />
    <Editor
     apiKey="akkadfn2r13cdt4d89n2v9o78m2wgdl383vkon4h8v2wkfvg"
     initialValue={props.content}
     init={{
       media_url_resolver: function (data, resolve) {
         if (window.location.pathname.match(/admin\/pages/).length){
            var REGEX_YOUTUBE = /^https?:\/\/www.youtube.com\/watch\?v=(.+)|^https?:\/\/youtu.be\/(.+)/;
            var matches = data.url.match(REGEX_YOUTUBE);
            resolve({html: '<div class="videoWrapper"><iframe src="//www.youtube.com/embed/' + (matches[1] || matches[2]) +'" width="560" height="314" frameBorder="0" allowfullscreen="allowfullscreen"></iframe></div>'});
        }},
       height: 400,
       menubar: false,
       formats: {
          title: { block: 'h2' },
          purple: { inline: 'span', classes: 'purple' },
          cyan: { inline: 'span', classes: 'cyan' },
          green: { inline: 'span', classes: 'green' },
          orange: { inline: 'span', classes: 'orange' }
        },
        style_formats: [
          { title: 'Title', format: 'title' },
          { title: 'Orange', format: 'orange' },
          { title: 'Green', format: 'green' },
          { title: 'Purple', format: 'purple' },
          { title: 'Cyan', format: 'cyan' }
      ],
      file_picker_types: 'image',
      image_title: true,
      image_caption: true,
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.onchange = function () {
          var file = this.files[0];
          var reader = new FileReader();
          reader.onload = function () {
            var id = 'blobid' + (new Date()).getTime();
            var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
            var base64 = reader.result.split(',')[1];
            var blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };
        input.click();
      },
      external_plugins: {
        'formplugin': '/tinymce_external_plugin/formplugin/plugin.js'
        },
       plugins: [
         'image link code paste lists advlist wordcount media help',

       ],
       content_css: '/tinymce_custom_v1.css',
       toolbar:
         'undo redo | cut copy paste | styleselect bold italic | bullist numlist | link formplugin media image | code help'
     }}
     onEditorChange={(editorContent) => setContent(editorContent)}
   />
   </div>
}
export default Tinymce
