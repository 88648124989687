require('lightbox2')
require('jquery-ui-bundle')
window.lity = require('lity');
$(document).ready(function() {

  $('#done_button').on('click', function() {
    $('#last_question').show();
    $(this).hide();
  })
  $(document).on('click', '[data-lightbox]', lity);

  var $hamburger = $(".hamburger");
  $hamburger.click(function(e) {
    $hamburger.toggleClass("is-active");
  });

  if (window.location.pathname == "/en" || window.location.pathname == "/es" || window.location.pathname == "/" ) {
    $('header').addClass("header-width");
  }

  const upload_button = document.getElementById('fileUpload');
    if (upload_button){
    upload_button.addEventListener('change', function(){
      $('.upload_submit').show();
  })}

  $hamburger.click(function(e) {
  	if ($(".menu").css("display") === "none") {
      $('html, body').css('overflow', 'hidden');
      $('.fascade').show();
  		$(".menu")
  			.show(0, function () {
  				$(this).animate({
  					left: "-=600px",
  					width: "600px"},
  					300);
  				});

  	} else {
      $('html, body').css('overflow', 'auto');
      $('.fascade').hide();
  		$(".menu")
  			.animate({left: "+=600px"},
  			300,
  			function() {
  				$(this).hide();
  			});
  	};
  });

  $('.edit_levels').hide();
  $('.manage_accounts').on('click', function() {
    $(this).parent().parent().find('.show_levels').toggle();
    $(this).parent().parent().find('.edit_levels').toggle();
  })

  $('.save_accounts').on('click', function() {
    var levels = [];
    $(this).parent().parent().find('.edit_levels input[type=checkbox]').each(function(i) {
      if ($(this).is(":checked")) {
        levels.push(i+1);
      }
    });
    console.log(levels);
    $(this).parent().text(' ... saving ...');
    $.get(location.href, {user_id: $(this).attr('data-user_id'), levels: levels}, function() { location.reload() });
  })

  if ($("#answered").val() == "true") {
    $('#last_question').show();
    $('#done_button').hide();
  }

  var selects = $(".slider_select");
  if(selects.length) {
    selects.each(function(index) {
      $("<div class='slider'></div>").insertAfter(this).slider({
        min: 0,
        max: 5,
        range: "min",
        value: $(this).val(),
        slide: function(event, ui) {
          $(this).prev()[0].selectedIndex = ui.value;
          $(this).next().find('span').css('opacity', '0.6');
          $(this).next().find('.mark_' + ui.value).css('opacity', '1');
        }
      });
    });
    $(".slider_select").hide();
  }

  var sliders = $(".sliders");
  if(sliders.length) {
    sliders.each(function(index) {
      $("<div class='slider'></div>").insertAfter(this).slider({
        disabled: true,
        min: 0,
        max: 5,
        range: "min",
        value: $(this).attr("data-value")
      })
    });
  }

  $("svg.orange, .orange_crevice, .orange_shape1, .orange_shape2, .orange_shape3, .enter_orange").mouseover(function() {
		$(".orange_dimension").addClass("active");
	});
	$(".orange, svg").mouseout(function() {
		$(".orange_dimension").removeClass("active");
	});

	$("svg.green, .green_crevice, .green_shape1, .green_shape2, .green_shape3, .enter_green").mouseover(function() {
		$(".green_dimension").addClass("active");
	});
	$(".green, svg").mouseout(function() {
		$(".green_dimension").removeClass("active");
	});

	$("svg.pink, .pink_crevice, .pink_shape1, .pink_shape2, .pink_shape3, .enter_pink").mouseover(function() {
		$(".pink_dimension").addClass("active");
	});
	$(".pink, svg").mouseout(function() {
		$(".pink_dimension").removeClass("active");
	});

	$("svg.blue, .blue_crevice, .blue_shape1, .blue_shape2, .blue_shape3, .enter_blue").mouseover(function() {
		$(".blue_dimension").addClass("active");
	});
	$(".blue, svg").mouseout(function() {
		$(".blue_dimension").removeClass("active");
	});

  $(".orange_dimension, .green_dimension, .pink_dimension, .blue_dimension").click(function(){
    // alert($(this).attr("data-url"))
    document.location=$(this).attr("data-url");
	});

});
